import { Crisp } from 'crisp-sdk-web';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { updateApiHeaderToken } from './api/base.api';
import AuthLayout from './pages/layouts/auth.layout';
import MainLayout from './pages/layouts/main.layout';
import {
  authTokenSelector,
  authUserSelector,
} from './pills/auth/auth.selectors';
import Router from './router/router';
import { PROFILE_MANAGER } from 'redux-orm-models/user.type';

function App({ userToken, user, init }) {
  const [initialized, setInitialized] = useState(false);

  useEffect(
    () => {
      updateApiHeaderToken(userToken);
      init();
      setInitialized(true);
    },
    // check https://github.com/facebook/react/issues/14920
    // eslint-disable-next-line
    [userToken],
  );

  useEffect(() => {
    const crispSiteId = process.env.REACT_APP_CRISP_SITE_ID;
    if (
      PROFILE_MANAGER === user?.currentProfile &&
      crispSiteId?.length > 0 &&
      window === window.top
    ) {
      try {
        Crisp.configure(crispSiteId);
        if (typeof window.$crisp !== 'undefined') {
          const userFullName =
            `${user?.firstName || ''} ${user?.lastName || ''}`.trim() || null;
          if (userFullName)
            $crisp.push(['set', 'user:nickname', [userFullName]]);
          if (user?.email) $crisp.push(['set', 'user:email', [user.email]]);
          if (user?.phone) $crisp.push(['set', 'user:phone', [user.phone]]);
          if (user?.companyName)
            $crisp.push(['set', 'user:company', [user.companyName]]);
        }
      } catch (err) {
        console.error(`Error init Crisp: `, err);
      }
    }
  }, [user?.currentProfile]);

  return (
    initialized && (
      <Router
        userToken={userToken}
        layout={userToken ? MainLayout : AuthLayout}
      />
    )
  );
}

function mapStateToProps(state) {
  return {
    userToken: authTokenSelector(state),
    user: authUserSelector(state),
  };
}
export default connect(mapStateToProps, (dispatch) => ({
  init: () => dispatch({ type: 'init' }),
}))(App);
